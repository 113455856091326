
.navbar{
    xposition: fixed;
    xtop: 0;
    xleft: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
}

.navbar, .buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.brand{
    font-weight: bolder;
    font-size: 1.5em;
}

.brand:hover{
    font-weight: bolder;
    color: #428aff;
}

button{
    border: none;
    transition: 0.3s;
    cursor: pointer;
}

.btn{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    background: #428aff;
    padding: 0.5em 1.3em;
    border-radius: 16px;
    color: #fff;
}

.btn:hover{
    background: #3877db;
}

.menu-btn{
    margin-left: 1em;
    background: none;
    display: inline-block;
}

.menu-btn span{
    font-size: 2.3em;
}

.dropdown > div, .sub-dropdown > div {
    cursor: pointer;
}

.dropdown .menu a:hover{
    color: #9b9b9b;
}

.navbar {

  background: #805c4e;
}

.dropdown > div > span {
  color: white;
  fonte: 13px;
}


    .navbar{
        xpadding: 0 1.5em;
    }
    .dropdown > div{
        padding: 16px 1em;
    }
    .mega-menu{
        display: flex;
        xjustify-content: center;
    }
    .menu-container {
      position: relative;
    }
    .menu-btn{
        display: none;
    }
    .menu{
        background: #fff;
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        padding: 0 2em;
        overflow-y: scroll;
        /* if this is on, it causes bug on moving between menu items*/
        xtransition: 0.4s ease-in-out;
        display: flex;
        xjustify-content: center;
        align-items: flex-start;
        gap: 2em;
    }

    .menu > li:first-child{
        font-size: 1.1em;
        font-weight: bold;
        margin: 0;
    }

    .sub-dropdown > div span:first-child{
        font-weight: bold;
    }

    .dropdown > div{
        xborder-bottom: 3px #fff solid;
        transition: 0.3s;
    }

    .mega-menu > li:first-of-type > div {
      padding-left: 0px;
    }


    .dropdown:hover > div{
      xborder-bottom: 3px #0D0805 solid;
    }

    .dropdown li{
        margin-bottom: 1em;
    }

    .dropdown > div span:last-child,
    .sub-dropdown > div{
        display: none;
    }

    /* Width */
    .menu::-webkit-scrollbar{
        width: 8px;
    }
    /* Track */
    .menu::-webkit-scrollbar-track{
        background: #f1f1f1;
    }
    /* Handle */
    .menu::-webkit-scrollbar-thumb{
        background: #888;
    }

    /* Handle on hover */
    .menu::-webkit-scrollbar-thumb:hover{
        background: #555;
    }

    /* Hide menu */
    .menu{
        height: 0;
    }
    /* .dropdown:hover .menu{ */
    .dropdown:hover .menu, .dropdown.show .menu{
      background-color: #ffd4c4;
        height: fit-content;
        padding: 14px 2em;
        padding-bottom: 0px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
